import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

const Input = ({ type, label, placeholder, required = true }) => {
  const intl = useIntl();
  const name = label.replace('contact.', '');
  return (
    <div className="mb-6 mr-6">
      <label
        htmlFor={label}
        className="block text-gray-700 text-sm mb-1.5 font-medium"
      >
        {intl.formatMessage({ id: label })}
      </label>
      <input
        name={name}
        id={name}
        className="border border-gray-light rounded-3p h-11 w-full py-2 px-3 text-gray-700"
        type={type}
        placeholder={intl.formatMessage({ id: placeholder })}
        required={required}
      />
    </div>
  );
};
export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
