import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

const InputSelect = ({ label, options }) => {
  const intl = useIntl();
  return (
    <div className="mb-6 mr-6">
      <label
        htmlFor={label}
        className="block text-gray-700 text-sm mb-1.5 font-medium"
      >
        {intl.formatMessage({ id: label })}
      </label>
      <select
        defaultValue=""
        name="role"
        className="border border-gray-light rounded-3p h-11 w-full px-2"
        required
      >
        <option value="" disabled>
          {intl.formatMessage({ id: 'contact.role.placeholder' })}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default InputSelect;

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};
