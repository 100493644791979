/* eslint-disable no-undef */
import React from 'react';
import { navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { H15 } from '../../components/Texts';
import contact from '../../images/use-3.png';
import ContactForm from '../../components/ContactForm';
import Layout from '../../components/layout'

const Contact = () => {
  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    fetch('https://submit-form.com/QxBg7WVu', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(formProps),
    })
      .then(() => {
        navigate(`${formProps.role}`, { state: { submitted: true } });
      });
  };
  return (
    <Layout>
      <div className="flex items-start mx-auto max-w-7xl">
        <div className="px-4 py-6 lg:pt-16 lg:pl-32 lg:w-7/12 mb-16 lg:flex-shrink-0">
          <p className="mb-3 text-green-700">
            {intl.formatMessage({ id: 'contact.subtitle' })}
          </p>
          <H15 className="mb-3">{intl.formatMessage({ id: 'contact.heading' })}</H15>
          <p className="mb-6">
            {intl.formatMessage({ id: 'contact.text1' })}
            <br />
            {intl.formatMessage({ id: 'contact.text2' })}
          </p>
          <hr />
          <p className="text-lg my-6">
            {intl.formatMessage({ id: 'contact.formTitle' })}
          </p>
          <ContactForm onSubmit={handleSubmit} />
        </div>
        <div className="hidden lg:flex">
          <img src={contact} alt="contact" />
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
