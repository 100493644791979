import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';

import PrimaryButton from '../Buttons/PrimaryButton';

import Input from './Input';
import InputSelect from './InputSelect';

const ContactForm = ({ onSubmit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const options = [
    { value: 'farmer', label: 'Productor' },
    { value: 'merchant', label: 'Comerciante' },
    { value: 'investor', label: 'Inversor' },
    { value: 'other', label: 'Otro' },
  ];

  const handleSubmit = (e) => {
    setLoading(true);
    onSubmit(e);
  };

  return (
    <form className="grid grid-cols-1 lg:grid-cols-2" onSubmit={handleSubmit}>
      <Input
        type="text"
        label="contact.name"
        placeholder="contact.name.placeholder"
      />
      <Input
        type="email"
        label="contact.email"
        placeholder="contact.email.placeholder"
      />
      <Input
        type="text"
        label="contact.phone"
        placeholder="contact.phone.placeholder"
      />
      <Input
        type="text"
        label="contact.company"
        placeholder="contact.company.placeholder"
        required={false}
      />
      <InputSelect label="contact.role.label" options={options} />
      <br />
      <div className="grid-cols-none flex space-x-2">
        <PrimaryButton
          type="submit"
          text={intl.formatMessage({ id: 'contact.submit' })}
          loading={loading}
        />
      </div>
    </form>
  );
};
export default ContactForm;

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
